import { PICKUP_METHODS } from "lib/constants"
import moment from "moment"
import React, { useReducer, useContext, useEffect } from "react"
import {
  ADD_ITEM_TO_CART,
  ADD_DISCOUNT_CODE,
  REMOVE_ITEM_FROM_CART,
  TOGGLE_SIDEBAR_HIDDEN,
  CLEAR_CART,
  CREATE_ORDER_OBJECT,
  UPDATE_PICKUP_DETAILS,
  UPDATE_PAYMENT_DETAILS,
  UPDATE_STORE_DELIVERY_FEE,
  UPDATE_CURRENT_STORE_DETAILS,
  UPDATE_DELIVERY_ADDRESS,
  UPDATE_GUEST_USER_DETAILS,
  UPDATE_CART_TOTAL,
  UPDATE_USER_AUTH,
  SET_TIME_WINDOW,
  SET_DELIVERY_WINDOW,
  UPDATE_PICKUP_WINDOW,
  SET_IS_DELIVERY_DISCOUNT,
  UPDATE_USER_DATA,
} from "./actionTypes"

const GlobalStateContext = React.createContext()
const GloablDispatchContext = React.createContext()

const initialState = {
  cashAmount: 0,
  carDetails: {
    licensePlate: "",
    carModel: "",
  },
  currentStoreDetails: {},
  deliveryFee: 0,
  deliveryAddress: {
    address: "",
  },
  deliveryWindow: "",
  discount: {
    value: 0,
  },
  isDeliveryDiscount: false,
  guestUserDetails: {},
  order: {},
  paymentMethod: "",
  pickupMethod: "",
  shoppingCart: [],
  shoppingCartIds: {},
  sidebarHidden: true,
  pickupTimeDate: false,
  timeWindow: null,
  total: 0,
  userAuth: {},
  userData: {},
}

const handleUpdateCart = (state, item) => {
  // Check is item exist in cart
  console.log(state)

  let isItemInCart = state.shoppingCartIds.hasOwnProperty(item.id)
  console.log({ isItemInCart })
  if (isItemInCart) {
    let newShoppingCart = state.shoppingCart.map(cartItem => {
      if (cartItem.id === item.id) {
        cartItem.count = item.count
      }
      return cartItem
    })
    return { ...state, shoppingCart: newShoppingCart }
  } else {
    let newCartIds = { ...state.shoppingCartIds }
    newCartIds[item.id] = item
    console.log({ newCartIds })
    let newShoppingCart = [
      ...state.shoppingCart,
      {
        ...item,
        storeId: state.currentStoreDetails.id,
        storeName: state.currentStoreDetails.name,
      },
    ]
    return {
      ...state,
      shoppingCart: newShoppingCart,
      shoppingCartIds: newCartIds,
    }
  }
}

const handleUpdateStoreDeliveryFee = (state, item) => {
  return { ...state, deliveryFee: item }
}

const handleRemoveFromCart = (state, itemToRemove) => {
  let cartIds = { ...state.shoppingCartIds }

  delete cartIds[itemToRemove.id]

  let newCartItems = state.shoppingCart.filter(
    item => item.id !== itemToRemove.id
  )

  return { ...state, shoppingCart: newCartItems, shoppingCartIds: cartIds }
}

const handleClearCart = state => {
  return {
    ...state,
    carDetails: {
      licensePlate: "",
      carModel: "",
    },
    cashAmount: 0,
    currentStoreDetails: {},
    deliveryAddress: {
      address: "",
    },
    deliveryWindow: "",
    deliveryFee: 0,
    discount: {
      value: 0,
    },
    isDeliveryDiscount: false,
    order: 0,
    paymentMethod: "",
    pickupMethod: "",
    shoppingCart: [],
    shoppingCartIds: {},
    pickupTimeDate: false,
    timeWindow: null,
    total: 0,
  }
}

const handleCreateOrder = state => {
  // Create cart object
  let cart = state.shoppingCart.map(cartItem => {
    if (cartItem.addons.length < 1) {
      return { ...cartItem, addonsPrice: 0 }
    }

    // Get addon total for item
    let addonTotal = cartItem.addons.reduce((total, current) => {
      if (current.applyPrice) {
        total = total + current.price
      }
      return total
    }, 0)

    //Get the addons in note format
    let addons = cartItem.addons.map(addon => addon.name).join(", ")

    return { ...cartItem, addonsPrice: addonTotal, note: addons }
  })

  let { guestUserDetails, userAuth } = state
  let name = guestUserDetails.fullName || userAuth.displayName
  let email = guestUserDetails.email || userAuth.email || "receipts@unqueue.app"
  let phone =
    guestUserDetails.phoneNumber || userAuth.phoneNumber || "+18687325456"
  let id = userAuth.uid || `${guestUserDetails.fullName}-${Date.now()}`

  const marketFee = state.currentStoreDetails.marketCompany
    ? parseFloat(((state.total - state.deliveryFee) * 0.05).toFixed(2))
    : 0

  let newOrder = {
    order: {
      cart,
      type: state.pickupMethod,
      total: state.total,
      paymentMethod: state.paymentMethod,
      deliveryAddress: { ...state.deliveryAddress },
      customerLicense: state.carDetails.licensePlate,
      cashAmount: Number(state.cashAmount ?? 0),
      carDetails: state.carDetails.carModel,
      specialNotes: "",
      discountApplied: {
        ...state.discount,
      },
      deliveryFee: state.deliveryFee + marketFee,
      marketFee,
      source: "web",
    },
    shopperData: {
      email: email,
      name: name ?? "Unqueue Shopper",
      id: id,
      phone: phone,
    },
    store: {
      ...state.currentStoreDetails,
      address: {
        ...state.currentStoreDetails.address,
        location: {
          latitude: state.currentStoreDetails.address.location._latitude,
          longitude: state.currentStoreDetails.address.location._longitude,
        },
      },
    },
  }

  //Check if discount applied
  if (!state.discount.value) {
    delete newOrder.order.discountApplied
  }

  // Attach Curbside / In-Store fields
  if (
    state.pickupMethod === PICKUP_METHODS.CURBSIDE ||
    state.pickupMethod === PICKUP_METHODS.IN_STORE
  ) {
    if (state.pickupTimeDate) {
      newOrder.order.pickupTimeDate = {
        ...state.pickupTimeDate,
        pickupDate: moment(state.pickupTimeDate?.pickupDate).toDate().toJSON(),
      }
    }
  }

  if (state.pickupMethod === PICKUP_METHODS.DELIVERY) {
    //Attach delivery fields
    if (state.deliveryWindow) {
      newOrder.order.deliveryWindow = state.deliveryWindow
    }
    if (state.timeWindow) {
      newOrder.order.timeWindow = state.timeWindow
    }
  }

  return newOrder
}

const handlePickUpDetailsChange = (state, item) => {
  console.log({ handlePickup: item })
  let updatedState = { ...state }

  switch (item.name) {
    case "pickupMethod":
      updatedState = { ...state, pickupMethod: item.value }
      break
    case "licensePlate":
      updatedState = {
        ...state,
        carDetails: { ...state.carDetails, licensePlate: item.value },
      }
      break
    case "carModel":
      updatedState = {
        ...state,
        carDetails: { ...state.carDetails, carModel: item.value },
      }
      break
    default:
      updatedState = { ...state }
  }

  return updatedState
}

const handlePaymentDetails = (state, item) => {
  let updatedState = { ...state }
  if (item.name === "paymentMethod") {
    updatedState.paymentMethod = item.value
  } else if (item.name === "cashAmount") {
    updatedState.cashAmount = item.value
  }

  return updatedState
}

const cartReducer = (state, action) => {
  switch (action.type) {
    // CREATE ACTIONS
    case ADD_ITEM_TO_CART: {
      return { ...handleUpdateCart(state, action.item) }
    }

    case ADD_DISCOUNT_CODE: {
      return {
        ...state,
        discount: action.item,
      }
    }

    case CREATE_ORDER_OBJECT: {
      return {
        ...state,
        order: handleCreateOrder(state),
      }
    }

    case TOGGLE_SIDEBAR_HIDDEN: {
      return {
        ...state,
        sidebarHidden: !state.sidebarHidden,
      }
    }

    // UPDATE ACTIONS

    case UPDATE_CART_TOTAL: {
      return {
        ...state,
        total: action.item,
      }
    }

    case UPDATE_CURRENT_STORE_DETAILS: {
      return {
        ...state,
        currentStoreDetails: action.item,
      }
    }

    case UPDATE_DELIVERY_ADDRESS: {
      return {
        ...state,
        deliveryAddress: action.item,
      }
    }

    case UPDATE_GUEST_USER_DETAILS: {
      return {
        ...state,
        guestUserDetails: action.item,
      }
    }

    case SET_TIME_WINDOW: {
      return {
        ...state,
        timeWindow: action.item,
      }
    }

    case SET_DELIVERY_WINDOW: {
      return {
        ...state,
        deliveryWindow: action.item,
      }
    }

    case UPDATE_PAYMENT_DETAILS: {
      console.log({ handlePayment: action.item })
      let newState = handlePaymentDetails(state, action.item)
      return {
        ...newState,
      }
    }

    case UPDATE_PICKUP_DETAILS: {
      let newState = handlePickUpDetailsChange(state, action.item)
      return {
        ...newState,
      }
    }

    case UPDATE_STORE_DELIVERY_FEE: {
      let newState = handleUpdateStoreDeliveryFee(state, action.item)

      return {
        ...newState,
      }
    }

    case UPDATE_USER_AUTH: {
      return {
        ...state,
        userAuth: action.item,
      }
    }
    case UPDATE_USER_DATA: {
      return {
        ...state,
        userData: action.item,
      }
    }

    // DELETE ACTIONS
    case REMOVE_ITEM_FROM_CART: {
      return {
        ...handleRemoveFromCart(state, action.item),
      }
    }

    case CLEAR_CART: {
      return handleClearCart(state)
    }

    case UPDATE_PICKUP_WINDOW: {
      return {
        ...state,
        pickupTimeDate: action.item,
      }
    }
    case SET_IS_DELIVERY_DISCOUNT:
      return {
        ...state,
        isDeliveryDiscount: action.isDeliveryDiscount,
      }

    default:
      return { ...state }
  }
}

const GlobalContextProvider = ({ children }) => {
  let localState = false
  let appVersion = 0

  if (typeof window !== "undefined") {
    localState = JSON.parse(window.localStorage.getItem("state"))
    appVersion = window.localStorage.getItem("APP_VERSION")
  }

  let storedState =
    process.env.NEXT_PUBLIC_WEB_APP_VERSION === appVersion ? localState : null

  const [state, dispatch] = useReducer(cartReducer, storedState || initialState)

  typeof window !== "undefined" &&
    window.localStorage.setItem(
      "APP_VERSION",
      process.env.NEXT_PUBLIC_WEB_APP_VERSION
    )

  useEffect(() => {
    typeof window !== "undefined" &&
      window.localStorage.setItem("state", JSON.stringify(state))
  }, [state])

  return (
    <GlobalStateContext.Provider value={state}>
      <GloablDispatchContext.Provider value={dispatch}>
        {children}
      </GloablDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext)

  if (context === undefined) {
    throw new Error(
      "useGlobalState must be used within a GlobalContextProvider"
    )
  }
  return context
}

export const useGlobalDisptach = () => {
  const context = useContext(GloablDispatchContext)

  if (context === undefined) {
    throw new Error(
      "useGlobalDispatch must be used within a GlobalContextProvider"
    )
  }

  return context
}

export default GlobalContextProvider
